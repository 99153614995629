
<template>
  <div class="commodity-spike">
    <!-- <div class="head">
      <img
        class="back"
        src="../../assets/img/commoditySpike/back.png"
        alt="返回"
       @click="$goBack()"
      />
      <div class="title">砍价列表</div>
    </div>-->
    <van-nav-bar
      title="砍价列表"
      fixed
      left-text
      left-arrow
      @click-left="$goBack()"
      @touchmove.prevent
    />

    <div class="middle">
      <div class="content">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="box" v-if="goodsDate">
            <div class="goods-list" v-for=" item, index in goodsDate" :key="index">
              <!-- 秒杀商品列表 -->
              <div class="goods-item">
                <div class="title">
                  <!-- 用户点击跳转时间 传店铺id -->
                  <img
                    @click="JumpStore()"
                    class="brand-logo"
                    :src="item.shopInfo.imgUrl"
                    alt="品牌logo"
                  />
                  <span class="store-name">{{item.shopInfo.name}}</span>
                </div>
                <div class="good-info">
                  <img class="good-img" :src="item.picUrl" alt="商品图片" />
                  <div class="desc">
                    <span class="good-name van-multi-ellipsis--l2">{{item.name}}</span>

                    <div class="prices">
                      <span class="activeprice">￥{{item.bargainPrice}}</span>

                      <span class="orginprice">￥{{item.goodsSku.salesPrice}}</span>

                      <div
                        class="lable"
                        v-if="item.goodsSpu&&item.goodsSpu.freightTemplat&&item.goodsSpu.freightTemplat.name"
                      >{{(item.goodsSpu.freightTemplat.name)}}</div>
                    </div>

                    <div class="join-poeple">已有{{item.launchNum}}人参与</div>
                  </div>
                </div>
                <div class="robbing" @click="getId(item.id)">前去砍价</div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import bargainList from "../../api/Bargain/bargin";

export default {
  data() {
    return {
      current: 1, //请求的页数
      size: 20, //请求的条数
      goodsDate: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    onLoad() {
      this.current++;
      this.getGoodsList();
    },

    getGoodsList() {
      bargainList({
        current: this.current,
        size: this.size,
      }).then((res) => {
        console.log(this.$store.state.userDate);
        this.loading = false;
        this.goodsDate = [...this.goodsDate, ...res.data.data.records];
        if (res.data.data.records.length < this.size) {
          this.finished = true;
        }
      });
    },
    addGoodsList() {
      this.current++;
      this.getGoodsList();
    },
    getTime(index) {
      if (index === this.active) {
        return;
      }
      console.log(index);
      this.active = index;
      this.getActive;
    },

    getId(id) {
      //   获取用户点击的商品id 并跳转到相应的页面
      this.$router.push({
        path: "/bargainDatil",
        query: {
          id: id,
          session: this.$route.query.session,
        },
      });
    },
    JumpStore() {
      //获取店铺id 并跳转到店铺首页
    },
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
  },
  created() {
    console.log(navigator.userAgent, 'navigator.userAgent')
    this.getUser();

    this.$store.commit(
      "setSession",
      this.$route.query.session || '$2a$10$1VcjWPhmyHOx2i0CvutR/OhWvQpRzMK27jsXWRkQUWWY7pRm.fe.2'
    );

    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
    }
    this.getGoodsList();
  },
  mounted() {
    if (this.$route.query.session != null) {
      // this.session = this.$route.query.session;
      //设置session
      this.$store.commit("setSession", this.$route.query.session);
    }
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  beforeDestroy() {
    document.documentElement.style.fontSize = "16px";
  },
};
</script>

<style lang="less" scoped>
.commodity-spike {
  font-size: 0.32rem;
  height: 100vh;
  position: fixed;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.head {
  width: 100%;
  padding: 0.266667rem;
  background-color: white;
  display: flex;
  border-bottom: 1px solid #ccc;
  z-index: 1000;
  box-sizing: border-box;
}
.head .back {
  width: 0.666667rem;
  height: 0.64rem;
}
.head .title {
  flex: 1;
  padding-right: 0.666667rem;
  text-align: center;
  font-size: 0.453333rem;
  font-weight: 600;
}
.middle {
  flex: 1;
  overflow: hidden;
  background-color: #e64d42;
  box-sizing: border-box;
}
.content {
  background-color: #e64d42ff;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  background-image: url("../../assets/img/barginImg.png");
  background-position: center top;
  background-size: 100%;
  background-repeat: no-repeat;
}
/deep/ .van-list {
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
}
.middle::-webkit-scrollbar {
  display: none;
}
.content .box {
  margin-bottom: 2.666667rem;
  padding-top: 5.92rem;
}

.content .list {
  width: 90%;
  display: flex;
  margin: auto;
  align-items: center;
  color: white;
  margin-bottom: 0.293333rem;
  overflow-x: auto;
}

.goods-item {
  width: 90%;

  background-color: white;
  border-radius: 0.133333rem;
  margin: 0 auto 0.266667rem;
  padding: 0.373333rem 0.426667rem;
  box-sizing: border-box;
  margin-bottom: 0.533333rem;
}
.goods-item .title {
  display: flex;
  align-items: center;
  margin-bottom: 0.693333rem;
}
.goods-item .title .brand-logo {
  width: 0.666667rem;
  height: 0.666667rem;
  border-radius: 0.133333rem;
}
.title .store-name {
  font-size: 0.4rem;
  font-weight: 600;
  padding-left: 0.266667rem;
}
.good-info {
  display: flex;
  font-size: 0.4rem;
}
.good-info .good-img {
  width: 24.26667vw;
  height: 24.26667vw;
  border-radius: 0.266667rem;
  box-sizing: border-box;
  margin-right: 0.266667rem;
}
// .desc .good-name{

// }
.desc .prices {
  display: flex;
}
.desc .activeprice {
  color: #a80000;
  font-weight: 600;
  margin-right: 0.266667rem;
}
.desc .orginprice {
  color: #999;
  text-decoration: line-through;
  font-weight: 600;
}
.desc .lable {
  background-color: #ff5200ff;
  text-align: center;
  transform: scale(0.83);
  color: white;
  display: block;
  margin-left: 0.266667rem;
}
.join-poeple {
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.453333rem;
  /* margin-top: .453333rem; */
}
.robbing {
  margin: auto;
  height: 0.986667rem;
  color: white;
  font-size: 0.4rem;
  font-weight: 600;
  background-image: url("../../assets/img/commoditySpike/btn.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 0.533333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>